/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-02-12",
    versionChannel: "nightly",
    buildDate: "2025-02-12T00:06:39.694Z",
    commitHash: "14190ada4b36165b28e9d2bfedf1d64acd9aceac",
};
